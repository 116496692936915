import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, CardMedia, Typography, Button, Box, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

const CoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate API call
    fetch("https://jsonplaceholder.typicode.com/posts/4")
      .then((response) => response.json())
      .then((data) => {
        let coursess = [{ 
            'id' : '1',
            'title' : 'Interview Prep', 
            'description' : 'this is desc'
        },
        { 
            'id' : '2',
            'title' : 'Interview Prep', 
            'description' : 'this is desc'
        }]
        setCourses(coursess);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 5 }}>
      <Typography variant="h4" sx={{ mb: 3, textAlign: "center", fontWeight: "bold" }}>
        Available Courses
      </Typography>
      <Grid container spacing={3}>
        {courses.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.id}>
            <Card sx={{ height: "100%" ,
                borderRadius: '50px'
            }}>
              <CardMedia
                component="img"
                height="140"
                image={course.image || "https://via.placeholder.com/300"}
                alt={course.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {course.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {course.description}
                </Typography>
              </CardContent>
              <Box sx={{ textAlign: "center", mb: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/registration/${course.id}`}
                  sx={{ textTransform: "none" }}
                >
                  Register
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CoursesPage;
