import React from "react";
import { Box, Container, Typography, Toolbar, Link } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "black",
        color: "#fff",
        py: 2,
        borderTop: "2px solid #444",
        mt: 5,
        position: "relative",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {/* Left Section */}
          <Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
            © {new Date().getFullYear()} All Rights Reserved.
          </Typography>

          {/* Right Section */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <Link
              href="/terms"
              underline="hover"
              sx={{
                color: "#ddd",
                fontSize: "0.9rem",
                "&:hover": { color: "#fff" },
              }}
            >
              Terms and Conditions
            </Link>
            <Link
              href="/about"
              underline="hover"
              sx={{
                color: "#ddd",
                fontSize: "0.9rem",
                "&:hover": { color: "#fff" },
              }}
            >
              About Us
            </Link>
            <Link
              href="/privacy"
              underline="hover"
              sx={{
                color: "#ddd",
                fontSize: "0.9rem",
                "&:hover": { color: "#fff" },
              }}
            >
              Privacy Policy
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </Box>
  );
};

export default Footer;
