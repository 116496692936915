import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const testimonials = [
  {
    name: 'John Doe',
    text: 'This platform helped me immensely in preparing for my tech interviews! The courses are well-structured, and the content is easy to follow.',
  },
  {
    name: 'Jane Smith',
    text: 'I got placed at my dream company thanks to the well-prepared courses on Java and Spring Boot. Highly recommended!',
  },
  {
    name: 'Mark Wilson',
    text: 'The RabbitMQ course was extremely helpful. I learned practical tips and tricks that I could apply directly to my work.',
  },
  {
    name: 'Alice Cooper',
    text: 'The interview prep material here is top-notch! I feel more confident for my next technical round.',
  },
];

const LandingPage = () => {
  return (
    <Box>
      {/* First Section: Hero Banner */}
      <Box
        sx={{
          height: '100vh',
          backgroundImage: 'url(https://your-image-url.com/background.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          textAlign: 'center',
          backgroundAttachment: 'fixed',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        />
        <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1 }}>
          <Typography variant="h3" sx={{ mb: 3, fontWeight: 'bold' }}>
            Welcome to Interview Prep
          </Typography>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Get ready for your next tech interview with our curated courses on Java, Spring Boot, SQL, RabbitMQ, and more.
          </Typography>
        </Container>
      </Box>

      {/* Second Section: Testimonials */}
      <Box
        sx={{
          py: 10,
          backgroundColor: '#f5f5f5',
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              mb: 5,
              color: '#333',
            }}
          >
            What Our Students Say
          </Typography>
          <Box
            sx={{
              display: 'flex',
              overflow: 'hidden',
              position: 'relative',
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '80px',
              boxShadow: 10,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                animation: 'slideRightToLeft 6s infinite linear',
                '&:hover': {
                    animationPlayState: 'paused', // Pause animation on hover
                },
                '@keyframes slideRightToLeft': {
                '0%': { transform: 'translateX(0)' },
                '100%': { transform: 'translateX(-70%)' }, // Transition halfway (accounting for duplicates)
          },
              }}
            >
              {testimonials.map((testimonial, index) => (
                <Box
                  key={index}
                  sx={{
                    minWidth: '300px',
                    maxWidth: '400px',
                    padding: '20px',
                    margin: '30px',
                    backgroundColor: '#ffffffaa',
                    borderRadius: '80px',
                    boxShadow: 2,
                    textAlign: 'center',
                    color: '#333',
                  }}
                >
                  <Typography variant="h6" sx={{ fontStyle: 'italic' }}>
                    "{testimonial.text}"
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 2, fontWeight: 'bold' }}>
                    - {testimonial.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;


