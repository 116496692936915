import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation(); // To highlight the active link

  // Toggle drawer
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navItems = [
    { label: "Home", path: "/" },
    { label: "Courses", path: "/courses" },
    { label: "Contact", path: "/contact" },
  ];

  // Drawer Content for Mobile
  const drawer = (
    <Box sx={{ width: 250 }}>
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item.path}
            button
            component={Link}
            to={item.path}
            onClick={handleDrawerToggle}
          >
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{
                fontWeight: location.pathname === item.path ? "bold" : "normal",
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: "black",
        color: "white",
        height: 100,
        backdropFilter: "blur(10px)", // Optional blur effect
        boxShadow: "none",
      }}
    >
      <Toolbar>
        {/* Logo and Title */}
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <IconButton edge="start" color="inherit" sx={{ mr: 2 }}>
            <img
              src="https://via.placeholder.com/40"
              alt="Logo"
              style={{ borderRadius: "50%" }}
            />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", textTransform: "uppercase" }}
          >
            Interview Prep
          </Typography>
        </Box>

        {/* Navigation Links for Desktop */}
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          {navItems.map((item) => (
            <Button
              key={item.path}
              component={Link}
              to={item.path}
              sx={{
                color: location.pathname === item.path ? "white" : "white",
                mx: 1,
                fontWeight: location.pathname === item.path ? "bold" : "normal",
                textTransform: "none",
              }}
            >
              {item.label}
            </Button>
          ))}
        </Box>

        {/* Search Bar */}
        {/* <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            sx={{
              background: "white",
              borderRadius: "8px",
              input: { padding: "8px" },
              width: "200px",
              marginRight: 1,
            }}
          />
          <IconButton color="inherit">
            <SearchIcon />
          </IconButton>
        </Box> */}

        {/* Hamburger Menu for Mobile */}
        <IconButton
          edge="end"
          color="black"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {/* Drawer for Mobile Navigation */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
}

export default Header;
