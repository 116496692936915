import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CourseDetails = () => {
  const { courseId } = useParams();
  const [courseDetails, setCourseDetails] = useState(null);
  const [topics, setTopics] = useState([]);

  // Mock API to fetch course details with topics and subtopics
  const fetchCourseDetails = async () => {
    const data = {
      id: courseId,
      title: "Java Programming Essentials",
      description: "Learn the core concepts of Java programming with hands-on projects.",
      topics: [
        {
          title: "Introduction to Java",
          subtopics: ["What is Java?", "Installing Java", "First Java Program"],
        },
        {
          title: "Object-Oriented Programming",
          subtopics: ["Classes and Objects", "Inheritance", "Polymorphism", "Encapsulation"],
        },
        {
          title: "Java Collections Framework",
          subtopics: ["Lists", "Sets", "Maps", "Queues"],
        },
        {
          title: "Multithreading and Concurrency",
          subtopics: ["Threads", "Thread Lifecycle", "Synchronization"],
        },
      ],
    };

    setCourseDetails(data);
    setTopics(data.topics);
  };

  useEffect(() => {
    fetchCourseDetails();
  }, [courseId]);

  if (!courseDetails) {
    return <Typography>Loading course details...</Typography>;
  }

  return (
    <Container>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold", color: "white" }}>
          {courseDetails.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, fontWeight: "bold", color: "black" }}>
          {courseDetails.description}
        </Typography>

        {/* List of Topics */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5" sx={{ mb: 2, color: "white", fontWeight: "bold" }}>
            Topics Covered:
          </Typography>
          {topics.map((topic, index) => (
            <Accordion
              key={index}
              sx={{
                backgroundColor: "#333",
                color: "#fff",
                border: "1px solid #444",
                mb: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                aria-controls={`panel-${index}-content`}
                id={`panel-${index}-header`}
              >
                <Typography sx={{ fontWeight: "medium" }}>{topic.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {topic.subtopics.map((subtopic, subIndex) => (
                    <Card
                      key={subIndex}
                      sx={{
                        backgroundColor: "#444",
                        color: "#fff",
                        borderRadius: "8px",
                        boxShadow: 2,
                        mb: 2, // Space between cards
                        width: "100%", // Full width of parent container
                      }}
                    >
                      <CardContent>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {subtopic}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default CourseDetails;
